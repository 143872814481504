<template>
  <div class="card borders w-100 w-md-50 m-2">
    <!-- {{ ticket }} -->
    <div class="card-body">
      <h5 class="card-title title mb-2">#{{ ticket.ticket.id }}</h5>
      <div class="d-flex">
        <div>
          <p class="sub-title mb-2">
            <span>{{ ticket.ticket.name }}</span> | <span>{{ ticket.ticket.document }}</span>
          </p>
          <p class="label mb-2" v-if="ticket.offer.offer_groups">{{ ticket.offer.offer_groups.name }}</p>
          <p class="label">{{ ticket.offer.name }}</p>
        </div>
        <div class="ml-auto">
          <div
            class="ticket-type d-flex align-items-center justify-content-center"
          >
            <p class="m-0">
              Atribuido
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: [Array, Object],
  },
};
</script>

<style scoped lang="scss">
.card {
  max-width: 555px;
}
.borders {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 20px !important;
}
.title {
  color: #009488;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.sub-title {
  font-weight: 600;
  size: 15px;
  line-height: 22.5px;
  color: #141414;
}
.label {
  color: #7a7786;
  font-weight: 400;
  font-size: 15px;
  line-height: 22.5px;
}
.ticket-type {
  font-size: 14px;
  color: #009488;
  font-weight: 600;
  line-height: 17.07px;
  width: 131px;
  height: 40px;
  border-radius: 10px;
  background: #0094881a;
  text-align: center;

  p {
    color: #009488;
  }
}
</style>
