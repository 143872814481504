<template>
  <div class="form w-100 w-md-50 p-2 order-3 order-md-3">
    <div class="d-flex align-items-center mb-4">
      <h3 class="title">Selecione o ingresso que deseja atribuir</h3>
    </div>
    <div class="d-flex flex-wrap overflow-auto mx-h">
      <div
        v-for="(item, index) in ticket"
        :key="index"
        class="card borders w-50 w-md-50 m-2"
        @click="onCardClick(item)"
      >
        <div class="card-body">
          <h5 class="card-title title mb-2">#{{ item.ticket_id }}</h5>
          <div class="d-flex">
            <div>
              <p class="sub-title mb-2" v-if="item.offer.offer_groups">
                <span>{{ item.offer.offer_groups.name }}</span>
              </p>
              <p class="label">{{ item.offer.name }}</p>
            </div>
            <div class="ml-auto mb-2">
              <div
                class="ticket-type"
              >
                <p 
                  :class="item.ticket && item.ticket.name !== null ? 'assigned' : 'unassigned'">
                  {{ item.ticket && item.ticket.name !== null ? "Atribuido" : "Não atribuido" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: [ Object, Array ],
  },
  methods: {
    onCardClick(index) {
      this.$emit("cardClick", index);
    },
  },
};
</script>

<style scoped lang="scss">
.mx-h {
  max-height: 65vh !important;
}
.card {
  width: 300px !important;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
    box-shadow: 1px 5px 10px #ccc;
  }

  @media screen and (max-width: 1500px) {
    width: 280px !important;
  }

  @media screen and (max-width: 1410px) {
    width: 100% !important;
  }
}
.borders {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px !important;
}
.title {
  color: #009488;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22.5px;
  color: #141414;
}
.label {
  color: #7a7786;
  font-weight: 400;
  font-size: 15px;
  line-height: 22.5px;
}
.ticket-type {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .assigned {
    background: #0094881A;
    color: #009488;
    align-items: center;
    text-align: center;
    width: 131px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .unassigned {
    background: #ff9e441a;
    color: #ff9e44;
    align-items: center;
    text-align: center;
    width: 131px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
