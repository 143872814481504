<template>
  <div class="form w-100 w-md-50 p-2 order-3 order-md-3">
    <div class="d-flex align-items-center mb-4">
      <h3 class="title">
        <font-awesome-icon
          style="padding-top: 1px; margin-right: 10px; cursor: pointer;"
          title="Voltar"
          @click.prevent="backToCard"
          class="icon-cyan"
          :icon="['fas', 'arrow-left']"
        />
        Resgate seus ingressos
      </h3>
      <p class="sub-title ml-auto">
        {{ this.ticketToRescue.length }} de {{ this.ticketAmount }} resgatados
      </p>
    </div>
    <div class="form-ticket borders">
      <div class="ticket-info d-flex align-items-center mb-4">
        <div class="lote">
          <p class="font-weight-bold mb-1" v-if="assigned.offer.offer_groups">
            {{ assigned.offer.offer_groups.name }}
          </p>
          <span>{{ assigned.offer.name }}</span>
        </div>
        <div class="ml-auto">
          <div
            class="ticket-type d-flex align-items-center justify-content-center"
          >
            <p
              :class="
                assigned.ticket && assigned.ticket.name !== null
                  ? 'assigned'
                  : 'unassigned'
              "
            >
              {{
                assigned.ticket && assigned.ticket.name !== null
                  ? "Atribuido"
                  : "Não atribuido"
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <p class="label">
          Preencha os campos abaixo com as informações do responsável por este
          ingresso.
        </p>
      </div>

      <div>
        <form>
          <div class="row">
            <div class="col-md-6 mb-2">
              <b-form-group label="CPF do responsável" label-for="cpf">
                <b-form-input
                  placeholder="###.###.###/##"
                  id="cpf"
                  name="cpf"
                  v-model="assignmentTicket.document"
                  v-validate="'required'"
                  type="text"
                  class="form-field"
                  :disabled="disabled()"
                  v-mask="['###.###.###-##']"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('cpf')">
                  Insira um CPF valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group label="Nome do resposável" label-for="name">
                <b-form-input
                  placeholder="Nome completo"
                  id="name"
                  name="name"
                  v-model="assignmentTicket.name"
                  :disabled="disabledFields"
                  v-validate="'required'"
                  type="text"
                  class="form-field"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  Insira o nome do responsável
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="col-md-6 mb-3">
              <b-form-group label="E-mail do responsável" label-for="email">
                <b-form-input
                  placeholder="responsavel@mail.com"
                  id="email"
                  name="email"
                  v-model="assignmentTicket.email"
                  :disabled="disabledFields"
                  v-validate="'required'"
                  type="text"
                  class="form-field"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('email')">
                  Insira o email do responsável
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-3">
              <b-form-group label="Telefone de contato" label-for="cellphone">
                <b-form-input
                  placeholder="(11) 9 2233-4455"
                  id="cellphone"
                  name="cellphone"
                  v-model="assignmentTicket.cellphone"
                  :disabled="disabledFields"
                  v-validate="'required'"
                  type="text"
                  class="form-field"
                  v-mask="['(##) # ####-####']"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('cellphone')">
                  Insira o telefone do responsável
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <button
            class="w-100 p-3 btn bg-gradient"
            @click.prevent="assignment"
            :class="!disabledFields ? 'effect' : ''"
            type="submit"
            :disabled="disabledFields"
          >
            {{ disabledFields ? "Ingresso já atribuído" : "Atribuir ingresso" }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PresentialEventService from "@/services/resources/PresentialEventService";

const service = PresentialEventService.build();

export default {
  props: {
    assigned: {
      type: [Object, Array],
      required: false,
    },
    ticketToRescue: {
      type: Array,
    },
    ticketAmount: {
      type: Number,
    },
  },
  data() {
    return {
      disabledFields: false,
      assignmentTicket: {
        name: "",
        document: "",
        cellphone: "",
        email: "",
      },
    };
  },
  watch: {
    assigned: {
      handler(newAssigned, oldAssigned) {
        if (newAssigned.ticket !== null && newAssigned.ticket.name !== null) {
          this.assignmentTicket = {
            name: newAssigned.ticket.name,
            document: newAssigned.ticket.document,
            cellphone: newAssigned.ticket.cellphone,
            email: newAssigned.ticket.email,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    backToCard() {
      this.$emit("backToCard");
    },
    disabled() {
      if (this.assigned.ticket !== null && this.assigned.ticket.name !== null) {
        this.disabledFields = true;
        return true;
      }

      return false;
    },
    assignment() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const url = "/ticket/assignment/" + this.assigned.ticket_id;

          service
            .update(this.assignmentTicket, url)
            .then(() => {
              this.backToCard();
              this.$parent.assignedTick();
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao atribuir Ingresso.", {
                variant: "danger",
                title: "Ingressos",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #009488;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.sub-title {
  color: #009488;
  font-weight: 500;
  font-size: 14px;
}
.borders {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 30px;
}
.ticket-type {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .assigned {
    background: #0094881a;
    color: #009488;
    align-items: center;
    text-align: center;
    width: 131px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .unassigned {
    background: #ff9e441a;
    color: #ff9e44;
    align-items: center;
    text-align: center;
    width: 131px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
form {
  .bg-gradient {
    background: linear-gradient(to right, #92c127, #008c95 99%);
    color: #ffffff;
    font-weight: 600;
    border-radius: 10px;
    border: none;
  }
  .form-field {
    width: 90% !important;
    margin: 0;
    border-radius: 10px !important;
  }
  .effect {
    transition: box-shadow 0.3s ease-in-out;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;

    &:hover {
      box-shadow: 0 0 20px #6fc5ff50;
      transform: scale(1.03);
    }

    &:active {
      transition: all 0.25s;
      -webkit-transition: all 0.25s;
      box-shadow: none;
      transform: scale(1.01);
    }
  }
}
@media screen and (max-width: 768px) {
  .form-field {
    width: 100% !important;
  }
}
</style>
